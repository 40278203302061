import { HTMLInputTypeAttribute, InputHTMLAttributes, useState } from 'react'
import styles from './styles.module.css'

type Props = {
    color: 'red' | 'yellow' | 'green' | 'blue' | 'black' | undefined
}

export function Divisor({ color }: Props): JSX.Element {

    return <span className={[styles.divisor, styles[color ?? 'red']].join(' ')}></span>
}
