import { ads } from '@/strings'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import styles from './styles.module.css'

export function AdRectangle(): JSX.Element {

    const [name,setName] = useState('')

    function getAdName(){
        const nm = ads[Math.floor(Math.random() * ads.length)]
        setName(nm.rectangle)
    }

    useEffect(()=>{
        getAdName()
        setInterval(getAdName, 30000);
    },[])

    return <span className={styles.adArea}>
        {
            name && <Image width={770} height={200} src={`/${name}`} alt='patrocinador'/>
        }
    </span>
}
