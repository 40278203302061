import { FaEnvelope, FaFacebook, FaInstagram, FaMap, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import styles from './styles.module.css';
import Image from 'next/image';

interface Props {
    color?: 'red' | 'blue' | 'yellow' | 'green' | undefined
}

export function Footer({ color }: Props) {
    return <footer className={[styles.footer, styles[color ?? 'red']].join(' ')}>
        <div className={styles.footerContainer}>
            <div className={styles.footerSection}>
                <Image src='/logo.svg' width={224} height={112} className={styles.logo} alt='logomarca escotilha news' />
                <br />
                <audio controls>
                    <source src="https://stm35.srvstm.com:7366/stream" type="audio/mpeg" />
                    Seu navegador não suporta o elemento de áudio.
                </audio>
            </div>
            <div className={styles.footerSection}>
                <h3>Institucional</h3>
                <ul>
                    <li><a href="/sobre">Quem Somos</a></li>
                    <li><a href="/politica-de-privacidade">Política de Privacidade</a></li>
                    <li><a href="/termos-de-uso">Termos de uso</a></li>
                </ul>
            </div>
            <div className={styles.footerSection}>
                <h3>Contatos</h3>
                <div className={styles.contact}>
                    <FaEnvelope size={17} />
                    <p>radiotvwebescotilha@gmail.com</p>
                </div>
                <div className={styles.contact}>
                    <FaPhone size={17} />
                    <p>(85) 99220-6692</p>
                </div>
                <div className={styles.contact}>
                    <FaMap size={17} />
                    <p>Várzea Alegre – CE, 63540-000</p>
                </div>
            </div>
            <div className={styles.footerSection}>
                <h3>Redes Sociais</h3>
                <ul className={styles.socialIcons}>
                    <li>
                        <a href="https://www.facebook.com/share/jtCpgGyW13jP9cnC">
                            <FaFacebook size={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/escotilhanews/">
                            <FaInstagram size={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/+5585992206692">
                            <FaWhatsapp size={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@escotilhanews">
                            <FaYoutube size={25} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <p className={styles.footerBottom}>© {new Date().getFullYear()} <a href='https://escotilhanews.com.br'><strong>Rádio Escotilha</strong></a>. Todos os direitos reservados.</p>
    </footer>
}