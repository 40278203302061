

import Image from 'next/image'
import styles from './styles.module.css'

interface IParams {
    title: string
    urlLogoClick?: string
    color?: 'red' | 'blue' | 'yellow' | 'green' | undefined
}

export function HeaderSample(params: IParams) {
    return <header className={[styles.mainHeader, styles[params.color ?? 'red']].join(' ')}>
        <div className={styles.aligner}>
            <section className={styles.logoHeader}>
                <Image width={128} height={64} src="/logo.svg" alt="escotilha news" />
            </section>

            <section className={styles.title}>
                <a href={`/${params.urlLogoClick ?? ''}`}><h1>{params.title}</h1></a>
            </section>
        </div>
    </header>
}
