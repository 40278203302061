import Image from 'next/image';
import face from '../../../public/facebook.png';
import insta from '../../../public/instagram.png';
import whats from '../../../public/whatsapp.png';
import youtube from '../../../public/youtube.png';
import styles from './styles.module.css';
import { FaPlayCircle } from 'react-icons/fa';

export function HeaderLinks() {
    return <section className={styles.topBar}>
        <div className={styles.aligner}>
            <a href='https://www.radios.com.br/aovivo/radio-escotilha/214823' target='_blank'>
                <button className={styles.listenTheRadio}>
                    <FaPlayCircle color='white' size={23} />
                    <span>Ouvir rádio</span>
                </button>
            </a>

            <div className={styles.userLinks}>
                <ul className={styles.socialIcons}>
                    <li>
                        <a href="https://www.facebook.com/share/jtCpgGyW13jP9cnC">
                            <Image src={face} alt='facebook' width={25} height={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/escotilhanews/">
                            <Image src={insta} alt='instagram' width={25} height={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://wa.me/+5585992206692">
                            <Image src={whats} alt='whatsapp' width={25} height={25} />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@escotilhanews">
                            <Image src={youtube} alt='youtube' width={25} height={25} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
}